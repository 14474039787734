import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
// import { CustomerService } from '@logic-suite/shared/access';
import { KeyService } from './key.service';

@Injectable({
  providedIn: 'root',
})
export class HasKeyGuard {
  constructor(
    private auth: KeyService,
    private router: Router,
  ) {}

  canLoad(): boolean | UrlTree {
    const decoded = this.auth.getTokenPayload();
    if (decoded?.customerID && decoded?.mapID) {
      try {
        // Key is present and valid. Set customerID and move on
        // this.customer.set({ customerID: +decoded.customerID, name: decoded.sub, isGroup: false });
        return true;
      } catch (ex) {
        // Key is not valid. Inform user.
        console.error('Could not decode token!');
        return this.router.createUrlTree(['/not-valid'], { queryParamsHandling: 'preserve' });
      }
    }
    // Key is not present. Inform user.
    return this.router.createUrlTree(['/not-found']);
  }
}
