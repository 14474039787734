import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CacheModule } from '@logic-suite/shared/cache';
import { assignEnv } from '@logic-suite/shared/utils';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { HasKeyGuard } from './auth/has-key.guard';
import { KeyInterceptor } from './auth/key.interceptor';

assignEnv(environment);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CacheModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, // getEnv('production'),
      // Register the ServiceWorker immediately.
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
          canLoad: [HasKeyGuard],
        },
        {
          path: 'not-found',
          pathMatch: 'full',
          loadChildren: () => import('./views/errors/not-found.module').then(m => m.NotFoundModule),
        },
        {
          path: 'not-valid',
          pathMatch: 'full',
          loadChildren: () => import('./views/errors/not-valid.module').then(m => m.NotValidModule),
        },
        { path: '**', redirectTo: '' },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: KeyInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
