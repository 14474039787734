import { Component, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(updates: SwUpdate) {
    // Make sure we run on an updated version
    if (updates.isEnabled) {
      // Update once a new update is registered
      this.subscriptions.push(
        updates.versionUpdates
          .pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map(evt => ({
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion,
            }))
          )
          .subscribe(() => {
            updates.activateUpdate().then(() => document.location.reload());
          })
      );
      // Check every 5 minutes for updates
      this.subscriptions.push(timer(0, 5 * 60 * 1000).subscribe(n => updates.checkForUpdate()));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
