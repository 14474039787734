import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeyService } from './key.service';

@Injectable()
export class KeyInterceptor implements HttpInterceptor {
  constructor(private auth: KeyService) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const key = this.auth.getToken();
    const authReq = req.clone({ setHeaders: { 'X-Auth': `Bearer ${key}` } });
    return next.handle(authReq);
  }
}
